import React, { useEffect, useState } from 'react';
import { Grid, Typography, Select, MenuItem, InputLabel, CircularProgress, Button } from '@mui/material';
import { useFormik } from 'formik';
import Sidebar from '../components/Sidebar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';

const TransferReport = () => {
	const [transferSupplierPaymentSummaryData, setTransferSupplierPaymentSummaryData] = useState({});
	const [loading, setLoading] = useState(false);
	let navigate = useNavigate();
	const token = sessionStorage.getItem('Token');

	const handleGoBack = () => {
		navigate(-1);
	};

	const TransferSupplierPaymentSummaryByDate = async (values) => {
		setLoading(true);

		const Params = {
			from_date: values.update_date_from.format('YYYY-MM-DD'),
			to_date: values.update_date_to.format('YYYY-MM-DD'),
		};
		try {
			const response = await axios.post(
				'https://rockmanapi.nexlogssolutions.com/api/transfer-freight-collection-report/',
				Params,
				// 'http://127.0.0.1:8000/api/transfer-freight-collection-report/',
				// Params,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			setLoading(false);

			setTransferSupplierPaymentSummaryData(response.data);
		} catch (err) {
			setLoading(false);
			alert(err.message);
		}
	};
	const formik = useFormik({
		initialValues: {
			update_date_from: dayjs(),
			update_date_to: dayjs(),
		},
		onSubmit: (values) => {
			TransferSupplierPaymentSummaryByDate(values);
		},
	});
	return (
		<Grid
			container
			sx={{
				display: {xs:'contents', sm:'flex'},
			}}
		>
			<Grid id="sidebarContainer" item md={2}>
				<Sidebar />
			</Grid>
			<Grid
				item
				md={10}
				sx={{
					px: { xs: 1, sm: 5 },
				}}
			>
				<Grid item md={12} sx={{ mt: 3 }}>
					<Button sx={{ width: '100%' }} type="submit" variant="contained" onClick={handleGoBack}>
						Back
					</Button>
				</Grid>
				<Grid item md={12}>
					<Box>
						<Typography sx={{ mt: 4, mb: 2 }} variant="h6">
							Expenses Overview
						</Typography>
					</Box>
					<form onSubmit={formik.handleSubmit}>
						<Grid container spacing={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
							<Grid item md={4}>
								<LocalizationProvider dateAdapter={AdapterDayjs} locale="en" size="small">
									<InputLabel htmlFor="update_date_from">From</InputLabel>

									<DatePicker
										id="update_date_from"
										name="update_date_from"
										slotProps={{ textField: { size: 'small' } }}
										value={formik.values.update_date_from}
										// onChange={formik.handleChange}
										onChange={(date) => {
											formik.setFieldValue('update_date_from', date);
											setTransferSupplierPaymentSummaryData({});
										}}
										onBlur={formik.handleBlur}
										error={formik.touched.update_date_from && !!formik.errors.update_date_from}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item md={4}>
								<LocalizationProvider dateAdapter={AdapterDayjs} locale="en" size="small">
									<InputLabel htmlFor="update_date_from"> To</InputLabel>

									<DatePicker
										id="update_date_to"
										name="update_date_to"
										slotProps={{ textField: { size: 'small' } }}
										value={formik.values.update_date_to}
										onChange={(date) => {
											formik.setFieldValue('update_date_to', date);
											setTransferSupplierPaymentSummaryData({});
										}}
										onBlur={formik.handleBlur}
										error={formik.touched.update_date_to && !!formik.errors.update_date_to}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={12} md={2} sx={{width:{xs:'100%'}}}>
								<Button fullWidth type="submit" variant="contained" >
									Submit
								</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
				<Grid item md={12} sx={{ mt: 5 }}>
					<Box>
						<Typography sx={{ mt: 4 }} variant="p">
							Transfer Summary
						</Typography>
					</Box>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 100 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ minWidth: 100, paddingLeft: '1.5rem', fontWeight: 600 }}>
										Cash Dollar
									</TableCell>
									<TableCell align="center" sx={{ minWidth: 100, fontWeight: 600 }}>
										Cash Cedis
									</TableCell>
									<TableCell align="center" sx={{ minWidth: 100, fontWeight: 600 }}>
										Bank Dollar
									</TableCell>
									<TableCell align="center" sx={{ minWidth: 100, fontWeight: 600 }}>
										Bank Cedis
									</TableCell>
									<TableCell align="center" sx={{ minWidth: 100, fontWeight: 600 }}>
										Momo Cedis
									</TableCell>
									<TableCell align="center" sx={{ minWidth: 100, fontWeight: 600 }}>
										Dollars Total
									</TableCell>
									<TableCell align="center" sx={{ minWidth: 100, fontWeight: 600 }}>
										Cedis Total
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Object.keys(transferSupplierPaymentSummaryData).length == 0 ? null : loading ? (
									<CircularProgress sx={{ display: 'block', margin: '0 auto' }} />
								) : (
									<TableRow
										// key={freights.username}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell
											component="th"
											scope="row"
											sx={{ width: 100, paddingLeft: '1.5rem' }}
										>
											$ {transferSupplierPaymentSummaryData.cash_sum}
										</TableCell>
										<TableCell align="center">
											GHS {Math.round(transferSupplierPaymentSummaryData.cash_cedis_sum)}
										</TableCell>
										<TableCell align="center">
											$ {Math.round(transferSupplierPaymentSummaryData.bank_sum)}
										</TableCell>
										<TableCell align="center">
											GHS {Math.round(transferSupplierPaymentSummaryData.bank_cedis_sum)}
										</TableCell>
										<TableCell align="center">
											GHS {transferSupplierPaymentSummaryData.momo_cedis_sum}
										</TableCell>
										<TableCell align="center" sx={{ backgroundColor: 'green' }}>
											$ {Math.round(transferSupplierPaymentSummaryData.dollar_total)}
										</TableCell>
										<TableCell align="center" sx={{ backgroundColor: '#BF0A30' }}>
											GHS {Math.round(transferSupplierPaymentSummaryData.cedis_total)}
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid item md={12} sx={{ mt: 5 }}>
					<Box>
						<Typography sx={{ mt: 4 }} variant="p">
							Freight Summary
						</Typography>
					</Box>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 100 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ minWidth: 100, paddingLeft: '1.5rem', fontWeight: 600 }}>
										Cash Dollar
									</TableCell>
									<TableCell align="center" sx={{ minWidth: 100, fontWeight: 600 }}>
										Cash Cedis
									</TableCell>
									<TableCell align="center" sx={{ minWidth: 100, fontWeight: 600 }}>
										Bank Dollar
									</TableCell>
									<TableCell align="center" sx={{ minWidth: 100, fontWeight: 600 }}>
										Bank Cedis
									</TableCell>
									<TableCell align="center" sx={{ minWidth: 100, fontWeight: 600 }}>
										Momo Cedis
									</TableCell>
									<TableCell align="center" sx={{ minWidth: 100, fontWeight: 600 }}>
										Dollars Total
									</TableCell>
									<TableCell align="center" sx={{ minWidth: 100, fontWeight: 600 }}>
										Cedis Total
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Object.keys(transferSupplierPaymentSummaryData).length == 0 ? null : loading ? (
									<CircularProgress sx={{ display: 'block', margin: '0 auto' }} />
								) : (
									<TableRow
										// key={freights.username}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell
											component="th"
											scope="row"
											sx={{ width: 100, paddingLeft: '1.5rem' }}
										>
											$ {transferSupplierPaymentSummaryData.freight_cash}
										</TableCell>
										<TableCell align="center">
											GHS {Math.round(transferSupplierPaymentSummaryData.freight_cash_cedis)}
										</TableCell>
										<TableCell align="center">
											$ {Math.round(transferSupplierPaymentSummaryData.freight_bank)}
										</TableCell>
										<TableCell align="center">
											GHS {Math.round(transferSupplierPaymentSummaryData.freight_bank_cedis)}
										</TableCell>
										<TableCell align="center" sx={{}}>
											GHS {transferSupplierPaymentSummaryData.freight_momo_cedis}
										</TableCell>
										<TableCell align="center" sx={{ backgroundColor: 'green' }}>
											$ {Math.round(transferSupplierPaymentSummaryData.freight_dollar_total)}
										</TableCell>
										<TableCell align="center" sx={{ backgroundColor: '#BF0A30' }}>
											GHS {Math.round(transferSupplierPaymentSummaryData.freight_cedis_total)}
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid item md={12} sx={{ mt: 5 }}>
					<Box>
						<Typography sx={{ mt: 4 }} variant="p">
							Ground Total
						</Typography>
					</Box>
					<Paper elevation={1}>
						{Object.keys(transferSupplierPaymentSummaryData).length == 0 ? null : loading ? (
							<CircularProgress sx={{ display: 'block', margin: '0 auto' }} />
						) : (
							<Grid container>
								<Grid
									item
									md={4}
									sx={{
										p: 10,
										backgroundColor: 'green',
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'column',
									}}
								>
									<Typography sx={{}} variant="p">
										Total Received ($)
									</Typography>
									<Typography sx={{}} variant="p">
										{Math.round(transferSupplierPaymentSummaryData.dollars_grand_total)}
									</Typography>
								</Grid>
								<Grid
									item
									md={4}
									sx={{
										p: 10,
										backgroundColor: '#BF0A30',
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'column',
									}}
								>
									<Typography sx={{}} variant="p">
										Total Received (GHS)
									</Typography>
									<Typography sx={{}} variant="p">
										{Math.round(transferSupplierPaymentSummaryData.cedis_grand_total)}
									</Typography>
								</Grid>
								<Grid
									item
									md={4}
									sx={{
										p: 10,
										backgroundColor: '#E5B80B',
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'column',
									}}
								>
									<Typography variant="p">Supplier Payment ($)</Typography>
									<Typography variant="p">
										{Math.round(transferSupplierPaymentSummaryData.supplier_payment_total)}
									</Typography>
								</Grid>
							</Grid>
						)}
					</Paper>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default TransferReport;
