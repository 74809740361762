import React, { useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import PDFtest from '../pages/PDFtest';

function AddTransferForm(props) {
	const [usernameEntered, setUsernameEntered] = React.useState('');
	const { open, onClose, users, setOpenTP } = props;
	const [filteredData, setFilteredData] = React.useState([]);
	const [transferResult, setTransferResult] = React.useState(null);
	const [selectedUsername, setSelectedUsername] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const token = sessionStorage.getItem('Token');
	const staffUser = sessionStorage.getItem('User');
	const staffUserObject = JSON.parse(staffUser);

	// Define the invoice data
	const invoiceData = {
		invoiceNumber: transferResult && transferResult.id,
		company: transferResult && transferResult.customer_id.company_name,
		items: {
			description: transferResult && transferResult.details,
			cashDollar: transferResult && transferResult.cash,
			cashCedis: transferResult && transferResult.cash_cedis,
			momo: transferResult && transferResult.momo_cedis,
			bankDollar: transferResult && transferResult.bank,
			bankCedis: transferResult && transferResult.bank_cedis,
			TotalDollar: transferResult && transferResult.amount_sent_dollars.toFixed(2),
			TransferCharges: transferResult && transferResult.transfer_charges.toFixed(2),
			date: transferResult && transferResult.date,
		},
	};

	// Create a ref to the PDFtest component
	const pdfRef = useRef();

	// Handler to trigger PDF generation
	const handleGeneratePDF = () => {
		pdfRef.current.generatePDF();
	};

	const onSelectUsernameFromListHandler = (selectedUsername) => {
		setSelectedUsername(selectedUsername);
		setFilteredData([]);
	};

	const PostTransfer = async (values) => {
		setLoading(true);
		console.log('values', values)
		const transferValue = {
			username: values.username,
			transfer_type: 'Transfer',
			details: values.details,
			cash: values.cash !== '' ? values.cash : 0,
			cashCedis: values.cashCedis !== '' ? values.cashCedis : 0,
			momo: values.momo !== '' ? values.momo : 0,
			bankCedis: values.bankCedis !== '' ? values.bankCedis : 0,
			bank: values.bank !== '' ? values.bank : 0,
			verify_momo: values.verify_momo,
			verify_cashCedis: values.verify_cashCedis,
			verify_cash: values.verify_cash,
			verify_bankCedis: values.verify_bankCedis,
			verify_bank: values.verify_bank,
			staff_user: staffUserObject.username,
			transferChargesCash: values.transferChargesCash !== '' ? values.transferChargesCash : 0,
			transferChargesCashCedis: values.transferChargesCashCedis !== '' ? values.transferChargesCashCedis : 0,
			transferChargesmomo: values.transferChargesmomo !== '' ? values.transferChargesmomo : 0,
			transferChargesBank: values.transferChargesBank !== '' ? values.transferChargesBank : 0,
			transferChargesBankCedis: values.transferChargesBankCedis !== '' ? values.transferChargesBankCedis : 0,
		};
		try {
			const response = await axios.post(
				'https://rockmanapi.nexlogssolutions.com/api/customertranfer/',
				transferValue,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			// const response = await axios.post('http://127.0.0.1:8000/api/customertranfer/', transferValue, {
			// 	headers: {
			// 		Authorization: `Token ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// });
			if (response.status == 200) {
				setTransferResult(response.data.data);
				setOpenTP(true);
				setLoading(false);
				alert('Transfer Created Successfully');
				handleGeneratePDF();
			}
		} catch (err) {
			if (err.response && err.response.status === 404) {
				// This will set the error message in the component state
				alert(err.response.data.error);
			} else {
				// Handle other errors (e.g., network errors)
				alert('An error occurred. Please try again later.');
			}
			// alert(err);
		}
	};

	const handleBankChange = (e) => {
		const bankValue = e.target.value;

		// Check if the bank field was filled and then removed
		if (formik.values.bank && !bankValue) {
			// Clear the verifyBank field
			formik.setFieldValue('verifyBank', '');
		}

		// Update the bank field
		formik.handleChange(e);
	};

	const handleCashChange = (e) => {
		const bankValue = e.target.value;

		// Check if the bank field was filled and then removed
		if (formik.values.cash && !bankValue) {
			// Clear the verifyBank field
			formik.setFieldValue('verifyCash', '');
		}

		// Update the bank field
		formik.handleChange(e);
	};

	const handleMomoChange = (e) => {
		const bankValue = e.target.value;

		// Check if the bank field was filled and then removed
		if (formik.values.momo && !bankValue) {
			// Clear the verifyBank field
			formik.setFieldValue('verifyMomo', '');
		}

		// Update the bank field
		formik.handleChange(e);
	};

	const formik = useFormik({
		initialValues: {
			username: selectedUsername,
			cash: '',
			cashCedis: '',
			momo: '',
			bankCedis: '',
			bank: '',
			details: '',
			verify_momo: '',
			verify_cash: '',
			verify_cashCedis: '',
			verify_bank: '',
			verify_bankCedis: '',
			transferChargesCashCedis: '',
			transferChargesCash: '',
			transferChargesBankCedis: '',
			transferChargesBank: '',
			transferChargesmomo: '',
		},
		validationSchema: Yup.object({
			username: Yup.string().max(15, 'Must be 15 characters or less'),
			// amountInDollars: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
			cash: Yup.number().nullable(),
			momo: Yup.number().nullable(),
			bank: Yup.number().nullable(),
			details: Yup.string(),
		}),
		onSubmit: (values, { resetForm }) => {
			values.username = selectedUsername;
			setLoading(true);
			PostTransfer(values);
			resetForm();
		},
	});

	return (
		<>
			<Dialog open={open} onClose={onClose}>
				<DialogTitle
					sx={{ textAlign: 'center', fontSize: { xs: 'x-large', sm: 'xx-large' }, paddingBottom: '1.5rem' }}
				>
					Add Transfer
				</DialogTitle>
				<DialogContent sx={{ padding: 0 }}>
					<Box>
						<form onSubmit={formik.handleSubmit}>
							<Grid
								container
								// spacing={2}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									margin: 0,
									px: { xs: 4, sm: 8 },
									width: '100%',
								}}
							>
								<Grid item md={6} sx={{ paddingLeft: '0 !important' }}>
									<TextField
										size="small"
										margin="dense"
										id="username"
										label="Username"
										fullWidth
										variant="outlined"
										value={selectedUsername}
										onChange={(e) => {
											const searchWord = e.target.value;
											setUsernameEntered(searchWord);
											setSelectedUsername(searchWord);
											const newFilter = users.filter((value) => {
												return value.user_id.username
													.toLowerCase()
													.includes(searchWord.toLowerCase());
											});
											if (searchWord === '') {
												setFilteredData([]);
											} else {
												setFilteredData(newFilter);
											}
										}}
									/>
									{filteredData.length !== 0 && (
										<div className="dataResult">
											{filteredData.slice(0, 10).map((value, key) => (
												<div
													key={key}
													onClick={() => {
														onSelectUsernameFromListHandler(value.user_id.username);
													}}
												>
													<p>{value.user_id.username} </p>
												</div>
											))}
										</div>
									)}
								</Grid>
								<Grid item md={6} sx={{ pl: 2 }}></Grid>
								<Grid item md={12} sx={{ mt: { xs: 0, sm: 4 } }}>
									<Typography> payment Mode</Typography>
								</Grid>
								<Grid item md={6} sx={{ mt: 1 }}>
									<TextField
										size="small"
										id="cash"
										label="Cash Dollar"
										fullWidth
										variant="outlined"
										value={formik.values.cash}
										onChange={handleCashChange}
										onBlur={formik.handleBlur}
										error={formik.touched.cash && formik.errors.cash}
									/>
								</Grid>

								<Grid item md={6} sx={{ mt: 1, pl: 2 }}>
									{formik.values.cash && (
										<TextField
											size="small"
											id="verify_cash"
											label="verification Code"
											fullWidth
											variant="outlined"
											value={formik.values.verify_cash}
											onChange={formik.handleChange}
										/>
									)}
								</Grid>
								<Grid item md={6} sx={{ mt: 1 }}>
									<TextField
										size="small"
										id="cashCedis"
										label="Cash Cedis"
										fullWidth
										variant="outlined"
										value={formik.values.cashCedis}
										onChange={handleCashChange}
										onBlur={formik.handleBlur}
										error={formik.touched.cashCedis && formik.errors.cashCedis}
									/>
								</Grid>

								<Grid item md={6} sx={{ mt: 1, pl: 2 }}>
									{formik.values.cashCedis && (
										<TextField
											size="small"
											id="verify_cashCedis"
											label="verification Code"
											fullWidth
											variant="outlined"
											value={formik.values.verify_cashCedis}
											onChange={formik.handleChange}
										/>
									)}
								</Grid>
								<Grid item md={6} sx={{ mt: 1 }}>
									<TextField
										size="small"
										id="momo"
										label="Momo Cedis"
										fullWidth
										variant="outlined"
										value={formik.values.momo}
										onChange={handleMomoChange}
										onBlur={formik.handleBlur}
										error={formik.touched.momo && formik.errors.momo}
									/>
								</Grid>
								<Grid item md={6} sx={{ mt: 1, pl: 2 }}>
									{formik.values.momo && (
										<TextField
											size="small"
											id="verify_momo"
											label="Momo verification Code"
											fullWidth
											variant="outlined"
											value={formik.values.verify_momo}
											onChange={formik.handleChange}
										/>
									)}
								</Grid>
								<Grid item md={6} sx={{ mt: 1 }}>
									<TextField
										size="small"
										id="bank"
										label="Bank Dollar"
										fullWidth
										variant="outlined"
										value={formik.values.bank}
										onChange={handleBankChange}
										onBlur={formik.handleBlur}
										error={formik.touched.bank && formik.errors.bank}
									/>
								</Grid>
								<Grid item md={6} sx={{ mt: 1, pl: 2 }}>
									{formik.values.bank && (
										<TextField
											size="small"
											id="verify_bank"
											label=" verification Code"
											fullWidth
											variant="outlined"
											value={formik.values.verify_bank}
											onChange={formik.handleChange}
										/>
									)}
								</Grid>
								<Grid item md={6} sx={{ mt: 1 }}>
									<TextField
										size="small"
										id="bankCedis"
										label="Bank Cedis"
										fullWidth
										variant="outlined"
										value={formik.values.bankCedis}
										onChange={handleBankChange}
										onBlur={formik.handleBlur}
										error={formik.touched.bankCedis && formik.errors.bankCedis}
									/>
								</Grid>

								<Grid item md={6} sx={{ mt: 1, pl: 2 }}>
									{formik.values.bankCedis && (
										<TextField
											size="small"
											id="verify_bankCedis"
											label="verification Code"
											fullWidth
											variant="outlined"
											value={formik.values.verify_bankCedis}
											onChange={formik.handleChange}
										/>
									)}
								</Grid>

								<Grid item md={12} sx={{ mt: { xs: 0, sm: 4 } }}>
									<Typography> Transfer Charges </Typography>
								</Grid>
								<Grid container item md={12} spacing={2}>
									<Grid item md={6} sx={{ mt: 1 }}>
										<TextField
											size="small"
											id="transferChargesCash"
											label="TC Cash Dollar"
											fullWidth
											variant="outlined"
											value={formik.values.transferChargesCash}
											onChange={handleCashChange}
											onBlur={formik.handleBlur}
											error={
												formik.touched.transferChargesCash && formik.errors.transferChargesCash
											}
										/>
									</Grid>
									<Grid item md={6} sx={{ mt: 1 }}>
										<TextField
											size="small"
											id="transferChargesCashCedis"
											label=" TC Cash Cedis"
											fullWidth
											variant="outlined"
											value={formik.values.transferChargesCashCedis}
											onChange={handleCashChange}
											onBlur={formik.handleBlur}
											error={
												formik.touched.transferChargesCashCedis &&
												formik.errors.transferChargesCashCedis
											}
										/>
									</Grid>
									<Grid item md={6} sx={{ mt: 1 }}>
										<TextField
											size="small"
											id="transferChargesBankCedis"
											label="TC Bank Cedis"
											fullWidth
											variant="outlined"
											value={formik.values.transferChargesBankCedis}
											onChange={handleBankChange}
											onBlur={formik.handleBlur}
											error={
												formik.touched.transferChargesBankCedis &&
												formik.errors.transferChargesBankCedis
											}
										/>
									</Grid>
									<Grid item md={6} sx={{ mt: 1 }}>
										<TextField
											size="small"
											id="transferChargesBank"
											label="TC Bank Dollar"
											fullWidth
											variant="outlined"
											value={formik.values.transferChargesBank}
											onChange={handleBankChange}
											onBlur={formik.handleBlur}
											error={
												formik.touched.transferChargesBank && formik.errors.transferChargesBank
											}
										/>
									</Grid>
									<Grid item md={6} sx={{ mt: 1 }}>
										<TextField
											size="small"
											id="transferChargesmomo"
											label=" TC Momo Cedis"
											fullWidth
											variant="outlined"
											value={formik.values.transferChargesmomo}
											onChange={handleMomoChange}
											onBlur={formik.handleBlur}
											error={
												formik.touched.transferChargesmomo && formik.errors.transferChargesmomo
											}
										/>
									</Grid>
								</Grid>
								<Grid item md={12} sx={{ paddingLeft: '0 !important', mt: 2 }}>
									<TextField
										size="small"
										margin="dense"
										id="details"
										label="Transfer details"
										fullWidth
										multiline
										rows={4}
										variant="outlined"
										value={formik.values.details}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.details && formik.errors.details}
									/>
								</Grid>
								<Box
									sx={{
										gap: { xs: '1rem', sm: '3rem' },
										display: 'flex',
										justifyContent: 'center !important',
										paddingBlock: '1rem',
										width: '100%',
										marginBottom: '1rem',
										marginTop: '.5rem',
									}}
								>
									<Button
										sx={{ width: '43%', height: { xs: '2rem', sm: '3rem' } }}
										variant="contained"
										onClick={onClose}
									>
										Cancel
									</Button>
									<Button
										sx={{ width: '43%', height: { xs: '2rem', sm: '3rem' } }}
										variant="contained"
										type="submit"
									>
										Submit
									</Button>
								</Box>
								{loading ? <CircularProgress sx={{ position: 'absolute', top: '50%' }} /> : null}
							</Grid>
						</form>
					</Box>
				</DialogContent>
			</Dialog>
			<PDFtest
				ref={pdfRef}
				invoiceData={invoiceData}
				username={selectedUsername}
				staffUser={staffUserObject.username}
			/>
		</>
	);
}

export default AddTransferForm;
