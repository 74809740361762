import React, { useState } from 'react';
import FreightListTable from '../components/FreightListTable';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import AddFreightForm from '../components/AddFreightForm';
import AddSMSForm from '../components/AddSMSForm';
import Sidebar from '../components/Sidebar';
import { useNavigate } from 'react-router-dom';
import AddFreightCreditForm from '../components/AddFreightCreditForm';

const useStyles = makeStyles((theme) => ({
	item: {
		paddingTop: '0 !important',
	},
	searchNButtonContainer: { marginBlock: '1.5rem', gap: 0.5 },
}));

function Freight() {
	const [users, setUsers] = useState([]);
	const [error, setError] = useState('');
	const [customerFreights, setCustomerFreights] = useState([]);
	const [open, setOpen] = useState(false);
	const [openUpdateFreightEdit, setUpdateFreightEdit] = useState(false);
	const [openCreditForFreight, setOpenCreditForFreight] = useState(false);
	const [openSendSMS, setOpenSendSMS] = useState(false);
	const token = sessionStorage.getItem('Token');

	let navigate = useNavigate();

	const fetchData = () => {
		const customerList = 'https://rockmanapi.nexlogssolutions.com/api/customerlist/';
		// const customerList = 'http://127.0.0.1:8000/api/customerlist/';
		const customerFreightList = 'https://rockmanapi.nexlogssolutions.com/api/customerFreightView/';
		// const customerFreightList = 'http://127.0.0.1:8000/api/customerFreightView/';

		axios
			.get(customerList, {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				const allDataCustomerList = response.data;
				setUsers(allDataCustomerList);
				return axios.get(customerFreightList, {
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				});
			})
			.then((response) => {
				const allDataSupplierPaymentList = response.data;
				setCustomerFreights(allDataSupplierPaymentList);
			})
			.catch((error) => {
				console.error('Error fetching data:', error);
			});
	};

	// function updateCustomerFreightListTable() {
	// 	// let baseurl = 'https://rockmanapi.nexlogssolutions.com/api/customerFreightView/';
	// 	let baseurl = 'http://127.0.0.1:8000/api/customerFreightView/';
	// 	axios
	// 		.get(baseurl)
	// 		.then((res) => {
	// 			setCustomerFreights(res.data);
	// 		})
	// 		.catch((error) => {
	// 			setError(error);
	// 		});
	// }

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		window.location.reload();
		setOpen(false);
	};

	const handleCloseUpdateFreightEdit = () => {
		window.location.reload();
		setUpdateFreightEdit(false);
	};

	const handleClickOpenCreditForFreight = () => {
		setOpenCreditForFreight(true);
	};
	const handleCloseOpenCreditForFreight = () => {
		window.location.reload();
		setOpenCreditForFreight(false);
	};
	const handleClickOpenSMS = () => {
		setOpenSendSMS(true);
	};
	const handleCloseSMS = () => {
		setOpenSendSMS(false);
	};
	React.useEffect(() => {
		fetchData();
	}, []);

	React.useEffect(() => {
		// const token = sessionStorage.getItem('Token');
		if (token == null || token == '') {
			navigate('/login');
		}
	});

	const classes = useStyles();

	return (
		<Grid container>
			<Grid id="sidebarContainer" item md={2}>
				<Sidebar />
			</Grid>
			<Grid
				item
				md={10}
				sx={{
					'@media (max-width: 600px)': {
						display: 'contents',
					},
				}}
			>
				{/* <div id="freightTabLayout"> */}
				<Grid container sx={{ gap: { xs: '1rem', sm: 0 } }} className={classes.searchNButtonContainer}>
					<Grid item xs={12} sm={2} sx={{ paddingLeft: { sm: '1.5rem' } }}>
						<Button sx={{ width: '100%' }} variant="contained" onClick={handleClickOpenSMS}>
							Send SMS
						</Button>
					</Grid>
					<Grid item xs={12} sm={3} sx={{ margin: 'auto' }}>
						<Button
							sx={{ width: '100%' }}
							variant="contained"
							startIcon={<AddOutlinedIcon />}
							onClick={handleClickOpenCreditForFreight}
						>
							Freight Prepayment
						</Button>
					</Grid>
					<Grid item xs={12} sm={2} sx={{ margin: 'auto' }}>
						<Button
							sx={{ width: '100%' }}
							variant="contained"
							startIcon={<AddOutlinedIcon />}
							onClick={handleClickOpen}
						>
							Add Freight
						</Button>
					</Grid>
					<Grid item sm={1} sx={{ margin: 'auto' }}></Grid>

					<Grid item sm={3}></Grid>
				</Grid>
				<AddFreightCreditForm
					open={openCreditForFreight}
					onClose={handleCloseOpenCreditForFreight}
					users={users}
				/>
				<AddFreightForm open={open} onClose={handleClose} users={users} />
				<AddSMSForm openSendSMS={openSendSMS} onClose={handleCloseSMS} />
				<FreightListTable
					customerFreights={customerFreights}
					open={openUpdateFreightEdit}
					onClose={handleCloseUpdateFreightEdit}
				/>
				{/* </div> */}
			</Grid>
		</Grid>
	);
}

export default Freight;
