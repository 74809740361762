import React, { useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PDFInvoiceSupplierPayment from '../pages/PDFInvoiceSupplierPayment';

function AddSupplierPaymentForm(props) {
	const { open, onClose, users } = props;
	const [filteredData, setFilteredData] = React.useState([]);
	const [selectedUsername, setSelectedUsername] = React.useState('');
	const [filteredSupplierData, setFilteredSupplierData] = React.useState([]);
	const [supplierData, setSupplierData] = React.useState([]);
	const [supplierPaymentResult, setSupplierPaymentResult] = React.useState({});
	const [selectedSupplier, setSelectedSupplier] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [openConsignment, setOpenConsignment] = React.useState([]);
	const [balance, setBalance] = React.useState('');
	const token = sessionStorage.getItem('Token');
	const staffUser = sessionStorage.getItem('User');
	const staffUserObject = JSON.parse(staffUser);


	const roundUpToTwoDecimals = (value) => {
		return Math.ceil(value * 100) / 100;
	};

	// Define the invoice data
	const invoiceData = {
		invoiceNumber: supplierPaymentResult && supplierPaymentResult.id,
		company:
			supplierPaymentResult &&
			supplierPaymentResult.customer_id &&
			supplierPaymentResult.customer_id.company_name,
		items: {
			description: supplierPaymentResult && supplierPaymentResult.goods_desc,
			payment: supplierPaymentResult && supplierPaymentResult.goods_cost_dollars,
			currentBalance:
				supplierPaymentResult &&
				supplierPaymentResult.customer_id &&
				supplierPaymentResult.customer_id.tranfer_account_balance.toFixed(2),
			date: supplierPaymentResult && supplierPaymentResult.created_at,
			Supplier:
				supplierPaymentResult &&
				supplierPaymentResult.supplier_id &&
				supplierPaymentResult.supplier_id.company_name,
			PaymentMode:
				supplierPaymentResult &&
				supplierPaymentResult.payment_id &&
				supplierPaymentResult.payment_id.payment_mode,
			shippingMode: supplierPaymentResult && supplierPaymentResult.shipping_mode,
		},
	};

	// Create a ref to the PDFtest component
	const PDFInvoiceSupplierPaymentRef = useRef();

	// Handler to trigger PDF generation
	const handleGeneratePDF = () => {
		PDFInvoiceSupplierPaymentRef.current.generatePDF();
	};

	React.useEffect(() => {
		axios
			.get('https://rockmanapi.nexlogssolutions.com/api/supplierslist/', {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'application/json',
				},
			})
			// .get('http://127.0.0.1:8000/api/supplierslist/', {
			// 	headers: {
			// 		Authorization: `Token ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// })
			.then((res) => {
				setSupplierData(res.data);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);
	function getBalance(selectUsername) {
		if (selectUsername !== '') {
			let baseurl =
				'https://rockmanapi.nexlogssolutions.com/api/customerdetailsByUsername/' + selectUsername + '/';
			// let baseurl = 'http://127.0.0.1:8000/api/customerdetailsByUsername/' + selectUsername + '/';
			axios
				.get(baseurl, {
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				})
				.then((res) => {
					setBalance(res.data.tranfer_account_balance);
				})
				.catch((error) => {
					setError(error);
				});
		}
	}
	const onSelectUsernameFromListHandler = (selectedUsername) => {
		setSelectedUsername(selectedUsername);
		getBalance(selectedUsername);
		setFilteredData([]);
	};
	const onSelectSupplierFromListHandler = (selectedUsername) => {
		setSelectedSupplier(selectedUsername);
		setFilteredSupplierData([]);
	};

	const PostSupplierPayment = async (values) => {
		const supplierPaymentValue = {
			username: values.username,
			// balance: values.customerBalance,
			supplier: values.supplier,
			goods_cost_dollars: values.amountPaid,
			goods_cost_cedis: 0,
			goods_desc: values.goodsDescription,
			amount_paid: values.amountPaid,
			payment_id: {
				status: 'Completed',
				payment_mode: values.paymentMode,
				balance: 0,
				dept: 0,
				amount_sent_cedis: values.amountPaid,
				transaction_type: 'Supplier Payment',
			},
			goods_weight: values.goodsWeight || 0,
			created_at: values.created_at.format('YYYY-MM-DD'),
			shipping_mode: values.shippingMode,
			consignment_number: values.selectedConsignment,
		};
		try {
			const response = await axios.post(
				'https://rockmanapi.nexlogssolutions.com/api/supplierpayment/',
				supplierPaymentValue,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
				// 'http://127.0.0.1:8000/api/supplierpayment/',
				// supplierPaymentValue,
				// {
				// 	headers: {
				// 		Authorization: `Token ${token}`,
				// 		'Content-Type': 'application/json',
				// 	},
				// }
			);
			if (response.data.StatusCode == 99) {
				setLoading(false);
				alert(response.data.StatusMessage);
			} else {
				setSupplierPaymentResult(response.data.data);
				setLoading(false);
				alert('Supplier payment Created Successfully');
				onClose();
				handleGeneratePDF();
			}
		} catch (err) {
			// Handle Error Here
			console.error(err.message);
		}
	};

	const formik = useFormik({
		initialValues: {
			username: '',
			shippingMode: '',
			supplier: '',
			goodsWeight: '',
			goodsDescription: 'goods description',
			amountPaid: '',
			paymentMode: '',
			created_at: dayjs(),
			selectedConsignment: '',
		},
		validationSchema: Yup.object({
			username: Yup.string().max(15, 'Must be 15 characters or less'),
			shippingMode: Yup.string().required('Field is Required'),
			supplier: Yup.string().max(50, 'Must be 50 characters or less'),
			amountPaid: Yup.number().required('Required'),
			paymentMode: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
		}),
		onSubmit: (values, { resetForm }) => {
			values.username = selectedUsername;
			// values.customerBalance = balance;
			values.supplier = selectedSupplier;
			setLoading(true);
			PostSupplierPayment(values);
			resetForm();
		},
	});

	React.useEffect(() => {
		axios
			.get('https://rockmanapi.nexlogssolutions.com/api/consignmentlist/', {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'application/json',
				},
			})
			// .get('http://127.0.0.1:8000/api/consignmentlist/', {
			// 	headers: {
			// 		Authorization: `Token ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// })
			.then((res) => {
				setOpenConsignment(res.data);
			})
			.catch((error) => {
				alert('consignment not fetched !!!, contact you administrator');
			});
	}, []);
	return (
		<>
			<>
				<Dialog open={open}>
					<DialogTitle
						sx={{
							textAlign: 'center',
							fontSize: { xs: 'x-large', sm: 'xx-large' },
							paddingBottom: '1.5rem',
						}}
					>
						Pay Supplier
					</DialogTitle>
					<DialogContent>
						<Box>
							<form onSubmit={formik.handleSubmit}>
								<Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
									<Grid item md={5.5} xs={12}>
										<TextField
											size="small"
											margin="dense"
											id="username"
											label="Username"
											fullWidth
											variant="outlined"
											value={selectedUsername}
											onChange={
												(e) => {
													// (e.target.value);
													const searchWord = e.target.value;
													// setUsernameEntered(searchWord);
													setSelectedUsername(searchWord);
													const newFilter = users.filter((value) => {
														return value.user_id.username
															.toLowerCase()
															.includes(searchWord.toLowerCase());
													});
													if (searchWord === '') {
														setFilteredData([]);
													} else {
														setFilteredData(newFilter);
													}
												}
												// formik.handleChange
											}
											onBlur={formik.handleBlur}
											error={formik.touched.firstName && formik.errors.firstName}
										/>
										{filteredData.length != 0 && (
											<div className="dataResult">
												{filteredData.slice(0, 10).map((value, key) => {
													return (
														<div
															key={key}
															onClick={() => {
																onSelectUsernameFromListHandler(value.user_id.username);
															}}
														>
															<p>{value.user_id.username} </p>
														</div>
													);
												})}
											</div>
										)}
									</Grid>
									<Grid item md={5.5} xs={12}>
										<TextField
											type="number"
											size="small"
											margin="dense"
											id="customerBalance"
											label="Customer Balance"
											fullWidth
											variant="outlined"
											value={roundUpToTwoDecimals(balance)}
											disabled
										/>
									</Grid>
									<Grid item md={5.5} xs={12}>
										<TextField
											size="small"
											margin="dense"
											id="supplier"
											label="Supplier"
											fullWidth
											variant="outlined"
											value={selectedSupplier}
											onChange={(e) => {
												// (e.target.value);
												const searchWord = e.target.value;
												// setUsernameEntered(searchWord);
												setSelectedSupplier(searchWord);
												const newFilter = supplierData.filter((value) => {
													return value.company_name
														.toLowerCase()
														.includes(searchWord.toLowerCase());
												});
												if (searchWord === '') {
													setFilteredSupplierData([]);
												} else {
													setFilteredSupplierData(newFilter);
												}
											}}
											onBlur={formik.handleBlur}
											error={formik.touched.supplier && formik.errors.supplier}
										/>
										{filteredSupplierData.length != 0 && (
											<div className="dataResult">
												{filteredSupplierData.slice(0, 10).map((value, key) => {
													return (
														<div
															key={key}
															onClick={() => {
																onSelectSupplierFromListHandler(value.company_name);
															}}
														>
															<p>{value.company_name} </p>
														</div>
													);
												})}
											</div>
										)}
									</Grid>

									<Grid item md={5.5} xs={12}>
										<TextField
											type="number"
											size="small"
											margin="dense"
											id="goodsWeight"
											label="Goods Weight"
											fullWidth
											variant="outlined"
											value={formik.values.goodsWeight}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.goodsWeight && formik.errors.goodsWeight}
										/>
									</Grid>
									<Grid item xs={12} md={5.5} xs={12}>
										<TextField
											type="number"
											size="small"
											margin="dense"
											id="amountPaid"
											label="Amount Paid ($)"
											fullWidth
											variant="outlined"
											value={formik.values.amountPaid}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.amountPaid && formik.errors.amountPaid}
										/>
									</Grid>
									<Grid item md={5.5} xs={12}>
										<Select
											id="paymentMode"
											name="paymentMode"
											value={formik.values.paymentMode}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.paymentMode && Boolean(formik.errors.paymentMode)}
											displayEmpty
											inputProps={{ 'aria-label': 'Payment Mode' }}
											sx={{ width: '100%', mt: 1, mb: 0.5 }}
											size="small"
										>
											<MenuItem value="" disabled>
												Payment Mode
											</MenuItem>
											<MenuItem value="cash">Cash</MenuItem>
											<MenuItem value="bank">Banks</MenuItem>
										</Select>
									</Grid>
									<Grid item md={5.5} xs={12}>
										{/* DesktopDatePicker for paymentMode */}
										<LocalizationProvider dateAdapter={AdapterDayjs} locale="en" size="small">
											<DatePicker
												id="created_at"
												name="created_at"
												slotProps={{ textField: { size: 'small' } }}
												value={formik.values.created_at}
												onChange={(date) => formik.setFieldValue('created_at', date)}
												onBlur={formik.handleBlur}
												error={formik.touched.created_at && !!formik.errors.created_at}
											/>
										</LocalizationProvider>
									</Grid>
									<Grid item md={5.5} xs={12}>
										<Select
											id="shippingMode"
											name="shippingMode"
											value={formik.values.shippingMode}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.shippingMode && Boolean(formik.errors.shippingMode)}
											displayEmpty
											inputProps={{ 'aria-label': 'shipping mode' }}
											sx={{ width: '100%' }}
											size="small"
										>
											<MenuItem value="" disabled>
												Select shipping mode
											</MenuItem>
											<MenuItem value="sea">Sea</MenuItem>
											<MenuItem value="air">Air</MenuItem>
										</Select>
									</Grid>
									<Grid item md={11} xs={12}>
										<TextField
											select
											// labelId="selectedConsignment"
											id="selectedConsignment"
											name="selectedConsignment"
											label="Consignment number"
											value={formik.values.selectedConsignment}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={
												formik.touched.selectedConsignment && formik.errors.selectedConsignment
											}
											fullWidth
											variant="outlined"
											size="small"
											sx={{ color: 'red' }}
										>
											{openConsignment &&
												openConsignment.map((consignment) => (
													<MenuItem key={consignment.id} value={consignment.id}>
														{consignment.name}
													</MenuItem>
												))}
										</TextField>
									</Grid>
									<Grid item md={11} xs={12}>
										<TextField
											size="small"
											margin="dense"
											id="goodsDescription"
											label="Goods Description"
											fullWidth
											multiline
											rows={4}
											variant="outlined"
											value={formik.values.goodsDescription}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={formik.touched.goodsDescription && formik.errors.goodsDescription}
										/>
									</Grid>
									<Box
										sx={{
											gap: { xs: '1rem', sm: '2rem' },
											display: 'flex',
											justifyContent: 'center !important',
											paddingBlock: '1rem',
											width: '100%',
											paddingLeft: '16px',
										}}
									>
										<Button sx={{ width: '40%' }} variant="contained" onClick={onClose}>
											Cancel
										</Button>
										<Button
											sx={{ width: '40%', marginLeft: '0 !important' }}
											variant="contained"
											type="submit"
										>
											Submit
										</Button>
									</Box>
									{loading ? <CircularProgress sx={{ position: 'absolute', top: '50%' }} /> : null}
								</Grid>
							</form>
						</Box>
					</DialogContent>
				</Dialog>
				<PDFInvoiceSupplierPayment
					ref={PDFInvoiceSupplierPaymentRef}
					invoiceData={invoiceData}
					username={selectedUsername}
					staffUser={staffUserObject.username}
				/>
			</>
		</>
	);
}

export default AddSupplierPaymentForm;
