import React, { forwardRef, useImperativeHandle, useContext } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logo from '../img/rockman-logo.jpeg';
import instagramLogo from '../img/instagram.png';
import facebookLogo from '../img/facebook.png';
import AuthContext from '../components/context/AuthProvider';

const PDFtest = forwardRef((props, ref) => {
	useImperativeHandle(ref, () => ({
		generatePDF,
	}));
	const { currencyRate } = useContext(AuthContext);

	const loadImage = (src) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => resolve(img);
			img.onerror = reject;
			img.src = src;
		});
	};

	const generatePDF = async () => {
		const doc = new jsPDF();
		let yPos = 10;

		try {
			const logoImg = await loadImage(logo);
			const instagramImg = await loadImage(instagramLogo);
			const facebookImg = await loadImage(facebookLogo);

			const logoWidth = 50;
			const logoHeight = 30;
			const logoX = 10;
			const logoY = yPos;

			doc.addImage(logoImg, 'JPEG', logoX, logoY, logoWidth, logoHeight);

			const textX = logoX + logoWidth + 40;
			doc.setFontSize(14);
			doc.text('TRANSFER PAYMENT RECEIPT', textX + 25, logoY + logoHeight / 2, {
				align: 'left',
				baseline: 'middle',
			});

			const { invoiceData, username, staffUser } = props;
			yPos = logoY + logoHeight + 10;

			const lineY = logoY + logoHeight;
			doc.line(10, lineY, 200, lineY);
			yPos = lineY + 5;

			const leftTextX = 15;
			const rightTextX = 140;
			doc.setFontSize(8);
			doc.text('Katip Kasim Mah. Mermerciler Cad.', leftTextX, yPos, { align: 'left' });
			doc.text('10 Dantu Avenue', rightTextX, yPos, { align: 'left' });

			yPos += 3;
			doc.text('No:5, Kat:1 Yenikapi-Faith / Istanbul', leftTextX, yPos, { align: 'left' });
			doc.text('Awudome - Accra Ghana', rightTextX, yPos, { align: 'left' });

			yPos += 3;
			doc.text('Phone: +90 5367983257, +90 5316262399, +90 5315080528', leftTextX, yPos, { align: 'left' });
			doc.text('Phone: +233 595733830, +233 595034055', rightTextX, yPos, { align: 'left' });

			yPos += 6;

			const instagramTextX = leftTextX + 6;
			const facebookTextX = instagramTextX + 40;
			doc.text('rockman_logistics', instagramTextX, yPos, { align: 'left' });
			doc.text('Rockman Logistics', facebookTextX, yPos, { align: 'left' });
			doc.setFontSize(10);

			doc.text('Receipt Code:', facebookTextX + 90, yPos, { align: 'left' });
			doc.setFont('helvetica', 'bold');
			doc.setTextColor('#ff3c00');
			doc.text(`0124${invoiceData.invoiceNumber}`, facebookTextX + 115, yPos, { align: 'left' });
			doc.setFont('times', 'normal');
			doc.setTextColor('#000000');

			const secondLineY = logoY + logoHeight + 21;
			doc.line(10, secondLineY, 200, secondLineY);

			doc.setFontSize(10);
			doc.text(`Client name : `, leftTextX, yPos + 9, { align: 'left' });
			doc.setFont('helvetica', 'bold');
			doc.text(`${username}`, leftTextX + 22, yPos + 9, { align: 'left' });
			doc.setFont('times', 'normal');
			doc.text(`Company : `, leftTextX + 70, yPos + 9, { align: 'left' });
			doc.setFont('helvetica', 'bold');
			doc.text(`${invoiceData.company}`, leftTextX + 88, yPos + 9, { align: 'left' });
			doc.setFont('times', 'normal');
			doc.text(`Date : `, leftTextX + 140, yPos + 9, { align: 'left' });
			doc.setFont('helvetica', 'bold');
			doc.text(`${invoiceData.items.date}`, leftTextX + 150, yPos + 9, { align: 'left' });
			doc.setFont('times', 'normal');

			const thirdLineY = logoY + logoHeight + 28;
			doc.line(10, thirdLineY, 200, thirdLineY);

			doc.addImage(instagramImg, 'JPEG', leftTextX, yPos - 3, 5, 5);
			doc.addImage(facebookImg, 'JPEG', facebookTextX - 6, yPos - 3, 5, 5);

			yPos += 20;

			const item = invoiceData.items;
			const rows = [
                ['Description', item.description],
				['Cash Dollar', `$ ${item.cashDollar}`],
				['Cash Cedis', `GHS ${item.cashCedis}`],
				['Momo', `GHS ${item.momo}`],
				['Bank Dollar', `$ ${item.bankDollar}`],
				['Bank Cedis', `GHS ${item.bankCedis}`],
				['Total Transfer', `$ ${item.TotalDollar}`],
				['Transfer Charges', `$ ${item.TransferCharges}`],
                ['Grand Total', `$ ${(parseFloat(item.TotalDollar) + parseFloat(item.TransferCharges)).toFixed(2)}`]

			];

			doc.autoTable({
				startY: yPos,
				body: rows,
				theme: 'grid',
				styles: {
					cellPadding: [2, 2, 2, 5],
					fontSize: 10,
					lineColor: [0, 0, 0],
					lineWidth: 0.5,
                },
                didParseCell: function (data) {
                    if (data.row.index === 6 || data.row.index === 7 || data.row.index === 8) {
                        data.cell.styles.fontStyle = 'bold';
                        data.cell.styles.fillColor = [220, 220, 220];
                    }
                },
                // columnStyles: { 0: { fontStyle: 'bold' } },
                columnStyles: {
                    0: {
                        fontStyle: 'bold',
                        cellWidth: 50 // Set the width of the first column (you can adjust this value)
                    },
                    1: {
                        cellWidth: 'auto' // Let the second column automatically adjust
                    }
                },
			});

			doc.setFontSize(8);
			doc.text(`Current dollar rate used ${currencyRate}`, leftTextX, yPos + 85, { align: 'left' });
			doc.setFont('times', 'bold');
			doc.setFontSize(10);
			doc.text(
				`Staff name : ${staffUser} / Signature : ......................................`,
				leftTextX + 100,
				yPos + 90,
				{ align: 'left' }
			);

			doc.save(`${username}-T0124${invoiceData.invoiceNumber}-${invoiceData.items.date}.pdf`);
		} catch (error) {
			console.error('Error loading images:', error);
		}
	};

	return null;
});

export default PDFtest;
